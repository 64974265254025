const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}

// Doc ready

ready(() => {

	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();
			let hash = this.getAttribute('href');
			let target = document.querySelector(hash);
			let elementPosition = target.offsetTop;
			let offsetPosition = elementPosition;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});
		};
	});



	// BACK TO TOP

	//Get the button
	const topButton = document.getElementById('top');

	// When the user scrolls down 100px from the top of the document, show the button
	window.onscroll = function () {

		scrollFunction();

	};

	function scrollFunction() {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
			) {

			topButton.style.display = 'block';

		} else {

			topButton.style.display = 'none';

		}
	}

	// When the user clicks on the button, scroll to the top of the document
	topButton.addEventListener('click', backToTop);

	function backToTop() {

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

	}




	// CONTACTBOX FORM

	if (document.getElementById('nomesia_form')) {
		
		// SHOW FORM SELECT "TIPOLOGIA_LEAD" ONLY IF OPTIONS > 1

		const tipologiaLead = document.querySelector('#tipologia_lead');
		
		const tipologiaLeadOptions = tipologiaLead.options.length;
		
		if( tipologiaLeadOptions > 1 ) {

			tipologiaLead.parentElement.classList.remove('d-none');
			tipologiaLead.classList.add('required');

			const firstOption = new Option('Seleziona...', '');
			tipologiaLead.insertBefore(firstOption, tipologiaLead.firstChild);
			tipologiaLead.selectedIndex = 0;

			const validationDiv = document.createElement('div');
			validationDiv.classList.add('field_status');
			tipologiaLead.after(validationDiv);

		} else {

			tipologiaLead.parentElement.classList.add('d-none');

		}
		

		// FORM REPLACE

		// Submit button text "Richiedi informazioni" (instead of "Invia")
		const inputSumbit = document.querySelector('#nomesia_form input[type="submit"]');
		inputSumbit.value = 'Richiedi informazioni';

		// Add span class ast (asterisk red) on required input labels
		const inputRequired = document.querySelectorAll('.required');
		inputRequired.forEach(function(input) {

			const spanAst = document.createElement('span');
			spanAst.classList.add('ast');
			spanAst.textContent = '*';
			input.parentElement.querySelector('label').appendChild(spanAst);

		});


		// Onclick privacy cookie same as Contactbox

		const linkPolicy = document.querySelector('#nomesia_form #more_info_container label a');
		const linkMarketing = document.querySelector('#nomesia_form #marketing_container label a');
		const linkPrivacy = document.querySelector('#nomesia_form #privacy_container label a');

		linkPolicy.addEventListener('click', function(e){
			e.preventDefault();
		});
		linkMarketing.addEventListener('click', function(e){
			e.preventDefault();
		});
		linkPrivacy.addEventListener('click', function(e){
			e.preventDefault();
		});

	}

});

jQuery.noConflict()(function($) {

	"use strict";

	$(document).ready(function() {

		// Owl carousel init
		$('.owl-carousel').owlCarousel({
			loop: true,
			autoplay: true,
			lazyLoad: true,
			nav: true,
			navText: ['<i class="fa-solid fa-angle-left fa-xl" aria-hidden="true"></i>','<i class="fa-solid fa-angle-right fa-xl" aria-hidden="true"></i>'],
			dots: false,
			responsive: {
				0:{
					items: 1
				},
				576: {
					items: 2
				},
				991: {
					items: 3
				},
				1200: {
					items: 4
				}
			}
		})

	});

});